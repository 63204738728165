define([], function() {
    "use strict";

    var NumberFormatter = {

        fractionDigits: {
            EUR: 2,
            CRC: 0,
            USD: 2,
        },

        asDecimal: function(number, precision) {
            precision = precision || 2;
            var numberToFormat = parseFloat(parseFloat(number).toFixed(precision));
            return numberToFormat.toLocaleString('es', {
                minimumFractionDigits: 2
            });
        },

        parseFloat: function(number) {
            if (isNaN(number)) {
                return parseFloat(number.replace(/\./g, "").replace(",", "."));
            } else {
                return parseFloat(number);
            }

        },

        parseInt: function(number) {
            var val = this.parseFloat(number)
            if (!Number.isInteger(val)) {
                return NaN
            }
            return Number.parseInt(val)
        },

        /**
         * @param number
         * @param currency
         * @param options
         *   - only_symbol: return only the currency symbol, without number
         *   - with_symbol: return number with currency symbol
         */
        toCurrency: function(number, currency, options) {
            var ops = options || {}
            var locale = ops.locale || App.LANGUAGE
            var formatter = new Intl.NumberFormat(locale, {
                minimumFractionDigits: this.fractionDigits[currency],
                maximumFractionDigits: this.fractionDigits[currency]
            });

            if (number === null) {
                return ''
            }

            var formattedNumber = formatter.format(number)

            if (ops.with_symbol) {
                return formattedNumber + ' ' + currency;
            }

            if (ops.only_symbol) {
                return currency
            }

            return formattedNumber
        },

        getNumberRegex: function (config) {
            var decimalSeparator = config ? config.DECIMAL_SEPARATOR_SYMBOL : ',';
            var thousandSeparator = config ? config.THOUSAND_SEPARATOR_SYMBOL : '.';
            return new RegExp("^-?\\d+(\\" + thousandSeparator + "\\d{3})?(\\" + decimalSeparator + "\\d+)?$");
        }
    };

    return NumberFormatter;
});
