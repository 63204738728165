define([
    "jquery",
    "underscore",
    "backbone",
    ], function($, _, Backbone) {
        "use strict";

        return Backbone.Router.extend({

            routings: null,

            initialize: function() {
                this.routings = [];

                // Index
                this.routings.push({ key: "homeShow", path: /^.*$/, view: "home/show" });

                //Login
                this.routings.push({ key: "login", path: new RegExp(App.urlRegexTo("user/account/login") + ".*$"), view: "views/account/login" });

                //Account
                this.routings.push({ key: "accountRegister", path: new RegExp(App.urlRegexTo("user/account/register") + ".*$"), view: "views/account/register" });
                this.routings.push({ key: "accountEdit", path: new RegExp(App.urlRegexTo("user/account/edit") + ".*$"), view: "views/account/edit" });
                this.routings.push({
                    key: "accountUnsubscribe",
                    path: new RegExp(App.urlRegexTo("user/account/unsubscribe-from-commercials") + ".*$"),
                    view: "views/account/counter"
                });
                this.routings.push({ key: "accountConfirm", path: new RegExp(App.urlRegexTo("user/account/confirm") + ".*$"), view: "views/account/account-confirm" });

                // Shop
                this.routings.push({ key: "shopRegister", path: new RegExp(App.urlRegexTo("shop/shop/register") + ".*$"), view: "views/shop/register" });
                this.routings.push({ key: "shopCheckout", path: new RegExp(App.urlRegexTo("shop/shop/checkout") + ".*$"), view: "views/shop/checkout" });
                this.routings.push({ key: "shopPay", path: new RegExp(App.urlRegexTo("shop/shop/pay") + ".*$"), view: "views/shop/pay" });
                this.routings.push({ key: "shopPaymentOk", path: new RegExp(App.urlRegexTo("shop/shop/payment-ok") + ".*$"), view: "views/shop/paymentok" });
                this.routings.push({ key: "shopProductSelection", path: new RegExp(App.urlRegexTo("shop/shop/product-selection") + ".*$"), view: "views/shop/product-selection" });

                // Parking
                this.routings.push({ key: "parkingList", path: new RegExp(App.urlRegexTo("parkingdoor/parking/list") + ".*$") });
                this.routings.push({ key: "parkingEdit", path: new RegExp(App.urlRegexTo("parkingdoor/parking/edit") + ".*$"), view: "views/parking/edit" });
                this.routings.push({ key: "parkingEditSection", path: new RegExp(App.urlRegexTo("parkingdoor/parking/edit") + "/parking-lot$"), view: "views/parking/edit" });

                //ParkingLot
                this.routings.push({ key: "parkingLotEdit", path: new RegExp(App.urlRegexTo("parkingdoor/parking-lot/show") + ".*$"), view: "views/parking-lot/form" });
                this.routings.push({ key: "parkingLotEditInfo", path: new RegExp(App.urlRegexTo("parkingdoor/parking-lot/show") + "/info.*$"), view: "views/parking-lot/form" });
                this.routings.push({ key: "parkingLotPhotos", path: new RegExp(App.urlRegexTo("parkingdoor/parking-lot/show") + "/photos.*$"), view: "views/parking-lot/form" });
                this.routings.push({ key: "parkingLotOwners", path: new RegExp(App.urlRegexTo("parkingdoor/parking-lot/show-owners") + ".*$"), view: "views/parking-lot/owners" });
                this.routings.push({ key: "parkingLotEditInfo", path: new RegExp(App.urlRegexTo("parkingdoor/parking-lot/show") + "/rentals.*$"), view: "views/parking-lot/rentals" });
                this.routings.push({ key: "parkingLotRentals", path: new RegExp(App.urlRegexTo("parkingdoor/parking-lot/show-rentals") + ".*$"), view: "views/parking-lot/rentals" });
                this.routings.push({ key: "parkingLotAccess", path: new RegExp(App.urlRegexTo("parkingdoor/parking-lot/show-logs") + ".*$"), view: "views/parking-lot/show-logs" });
                // Remember password
                this.routings.push({ key: "requestPasswordReset", path: new RegExp(App.urlRegexTo("user/account/request-password-reset") + ".*$"), view: "views/account/request-password-reset" });

                this.routings.push({ key: "resetPassword", path: new RegExp(App.urlRegexTo("user/account/reset-password") + ".*$"), view: "views/account/reset-password" });

                //GoogleHomePin
                this.routings.push({ key: "GoogleHomePin", path: new RegExp(App.urlRegexTo("user/account/google-home-pin") + ".*$"), view: "views/account/google-home-pin" });
                this.routings.push({ key: "GoogleHomePinVerify", path: new RegExp(App.urlRegexTo("user/account/google-home-pin-verify") + ".*$"), view: "views/account/google-home-pin" });

                //Empty routes
                this.routings.push({ key: "terms", path: new RegExp(App.urlRegexTo("site/terms") + ".*$")});
                this.routings.push({ key: "error", path: /error$/ });

                this.routings.push({ key: "communityShow", path: new RegExp(App.urlRegexTo("site/landing-community") + ".*$"), view: "community/show" });
                
                this.routings.push({ key: "landingKickstarter", path: /venta-aniversario.*$/, view: "home/landing-kickstarter" });
                this.routings.push({ key: "landingSeptiembre2016", path: /oferta-septiembre-2016.*$/, view: "home/landing-kickstarter" });
                this.routings.push({ key: "landingChristmas", path: /oferta-navidad-2016.*$/, view: "home/landing-kickstarter" });

                this.generateRoutes();
            },

            empty: function() {},

            createView: function(key, view, args) {
                console.log(key + " - " + view);
                
                if (view){
                    var router = this;
                    if (/^views/.test(view)) {
                        require([ "views/" + view.replace("views/", "") ], function(View) {
                            /**
                                The reloadPage function is invoked here to prevent
                                a bucle of reloads when page is reloaded
                            */
                            router.on("route", router.reloadPage);
                            if (!App.Views[ view ]) {
                                var ViewHelper = (function() {
                                    return function ViewHelper() {
                                        return View.apply(this, args);
                                    };
                                })();
                                ViewHelper.prototype = View.prototype;

                                App.Views[ view ] = new ViewHelper(args);
                                App.Views[ view ].render();
                            }
                        });
                    } else {
                        require([ "pages/" + view ], function(View) {
                            /**
                                The reloadPage function is invoked here to prevent
                                a bucle of reloads when page is reloaded
                            */
                            router.on("route", router.reloadPage);
                            if (!App.Views[ view ]) {
                                var ViewHelper = (function() {
                                    return function ViewHelper() {
                                        return View.apply(this, args);
                                    };
                                })();
                                ViewHelper.prototype = View.prototype;

                                App.Views[ view ] = new ViewHelper(args);
                                App.Views[ view ].render();
                            }
                        });
                    }

                }
            },

            generateRoutes: function() {
                _.each(this.routings, function(r) {
                    r.path = new RegExp("(?:^|index.php\/)" + r.path.source);
                    this.route(r.path, r.key, function() { this.createView(r.key, r.view, arguments); }.bind(this));
                }, this);
            }

        });

    });
