define([
    "jquery",
    "underscore",
    "backbone"
    ], function($, _, Backbone) {

        "use strict";

        var FieldComponent = Backbone.View.extend({

            events: {
                "focus input"  : "onFocus",
                "focus select" : "onFocus",
                "blur input"   : "onBlur",
                "keydown input": "hideError",
                "blur select"  : "onBlur",
                "click .js-tooltip-question-cancel" : "onTooltipCancel",
                "click .js-tooltip-question-accept" : "onTooltipAccept"
            },

            initialize: function(options) {
                this.options = options || {};
                this.render();
            },

            hideTooltipQuestion: function() {
                $(".js-tooltip-question", this.$el).hide();
            },

            showTooltipQuestion: function() {
                $(".js-tooltip-question", this.$el).show();
            },

            onTooltipAccept: function() {
                this.trigger("textfield:tooltip-question-accept");
                this.hideTooltipQuestion();
            },

            onTooltipCancel: function() {
                this.trigger("textfield:tooltip-question-cancel");
                this.hideTooltipQuestion();
            },

            onFocus: function() {
                this.$el.addClass("is-focus");
            },

            onBlur: function() {
                this.$el.removeClass("is-focus");
            },

            disable: function() {
                this.$el.addClass("is-disabled");
                this.$input.attr("disabled", "disabled");
            },

            enable: function() {
                this.$el.removeClass("is-disabled");
                this.$input.attr("disabled", null);
            },

            focus: function() {
                setTimeout(function() {
                    this.$input.focus();
                }.bind(this), 100);
            },

            showError: function(message) {
                this.$el.addClass("field--error")
                    .find(".js-field-" + this.options.name + "-error")
                    .html(message);
            },

            hideError: function() {
                this.$el.removeClass("field--error");
            },

            clear: function() {
                if (this.$input.length) {
                    this.$input.val("");
                } else if (this.$dropdown.length) {
                    this.$dropdown.data("dropdown").setValue("");
                }

                this.hideError();
            },

            val: function(value) {
                if (value !== undefined) {
                    this.$input.val(value);
                } else {
                    return this.$input.val();
                }
            },

            render: function() {
                this.$input = this.$el.find(".js-field-input");
                this.$dropdown = this.$el.find(".js-dropdown");
            }

        });

        return FieldComponent;

    });
