define([
    "underscore",
    "backbone",
    "backbone.overrides",
    "components/top-menu"
    ], function(_, Backbone, BackboneOverrides, TopMenu) {
        "use strict";

        var HeaderView = App.View.extend({
            el: "header",

            events: {
                "click .js-responsive-menu"              : "toggleResponsiveMenu",
                "change .js-header-left-dropdown select" : "onClickDropdown"
            },

            initialize: function() {
                this.topMenu = new TopMenu();
            },

            onClickDropdown: function() {
                location.href = this.dropdowns["header-left-dropdown"].getValue();
            },

            toggleResponsiveMenu: function(e) {
                this.topMenu.toggle();
                e.preventDefault();
            }
        });

        return HeaderView;
    });
