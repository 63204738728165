    define([
    "jquery",
    "underscore",
    "backbone",
], function($, _, Backbone) {
        "use strict";

        var ModalQuestion = Backbone.View.extend({
            el: ".js-main-modal-question",
            callbackFunction: undefined,
            questionType: "alert",
            responseValue: null,
            enabled: false,

            initialize: function() {
                this.render();
                this.returnValue = false;
            },

            render: function() {
                this.$question = $(".js-modal-question__question", this.$el);
                $(".js-btn-modal", this.$el).click(this.buttonAction.bind(this));

                this.listenTo(App.Modal, "modal:close", this.onCloseModal.bind(this));
            },

            makeQuestion: function(question, questionType) {
                this.enabled = true;

                App.Modal.showModal(undefined, {
                    modalElement : this.$el
                });

                this.responseValue = null;
                this.$el.removeClass("question-confirm");
                this.$el.removeClass("question-alert");
                this.$el.removeClass("question-prompt");

                this.questionType = questionType;
                this.$el.addClass("question-" + questionType);

                this.returnValue = false;
                this.$question.html(question);
            },

            buttonAction: function(event) {
                event.stopPropagation();
                event.preventDefault();
                this.responseValue =  $(event.currentTarget).attr("data-button-value");
                App.Modal.closeModal();
            },

            onCloseModal: function(e) {
                var returnValue;
                if (this.enabled) {
                    this.enabled = false;
                    if (this.type == "prompt") {
                        returnValue = $("input", this.$el).value();
                    } else {
                        returnValue = (this.responseValue == "Ok");
                    }
                    this.trigger("modal-question:close", returnValue);
                }
            }
        });

        return ModalQuestion;
    });
