define([
    "jquery",
    "underscore",
    "backbone",
    ], function($, _, Backbone) {
        "use strict";

        var Checkbox = Backbone.View.extend({
            el: ".js-checkbox",
            is_enabled: true,

            events: {
                "click .js-checkbox-input" : "onClick",
                "click label"              : "onClickLabel"
            },

            initialize: function() {
                this.$el.data("checkbox", this);
            },

            onClickLabel: function(e) {
                e.stopPropagation();
            },

            onClick: function() {
                if (this.is_enabled) {
                    if (this.$el.find(".js-checkbox-input").prop("checked")) {
                        this.$el.find(".js-checkbox-input-hidden").val("1");
                    } else {
                        this.$el.find(".js-checkbox-input-hidden").val("0");
                    }
                }
            },

            checked: function(value, silent) {
                if (value === undefined){
                    return ($(".js-checkbox-input", this.$el).prop("checked") ? 1 : 0);
                } else {
                    var $checkbox = $(".js-checkbox-input", this.$el);
                    $checkbox.prop("checked", value);

                    if (!silent) {
                        $checkbox.trigger("change");
                    }
                }
            },

            showLoading: function() {
                this.disable();
                this.$el.addClass('is-loading');
            },
            
            hideLoading: function() {
                this.enable();
                this.$el.removeClass('is-loading');
            },

            enable: function() {
                this.is_enabled = true;
                this.$el.find(".js-checkbox-input").attr("disabled", null);
                this.$el.removeClass("is-disabled");
            },

            disable: function() {
                this.is_enabled = false;
                this.$el.find(".js-checkbox-input").attr("disabled", "disabled");
                this.$el.addClass("is-disabled");
            }
        });

        return Checkbox;

    });
