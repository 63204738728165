define([
    "jquery",
    "underscore",
    "backbone"
    ], function($, _, Backbone) {

        "use strict";

        var TopMenu = Backbone.View.extend({
            el: ".js-top-menu",

            events: {
                "click .js-top-menu-item": "onClick",
                "click .js-top-menu-background": "hide"
            },

            onClick: function() {
                this.hide();
            },

            toggle: function() {
                $("body").toggleClass("top-menu-is-visible");
            },

            hide: function() {
                $("body").removeClass("top-menu-is-visible");
            }

        });

        return TopMenu;

    });
