define([
    "jquery",
    "underscore",
    "backbone",
    "i18next/i18next",
    "backbone-nested"
], function($, _, Backbone, i18n) {
    "use strict";

    var UserModel = Backbone.Model.extend({
        urlRoot: function() {
            return App.urlTo("user/user/create");
        },

        defaults: {
            name: "",
            lastname: "",
            email: "",
            password: "",
            password_repeat: "",
            post_code: "",
            phone: "",
            dni: "",
            reCaptcha: ""
        },

        syncAttrs: [ "name", "lastname", "email", "dni", "password", "password_repeat", "phone", "post_code", "reCaptcha" ],

        validation: {
            name: [ {
                required: true,
                msg: function() { return i18n.t("user.name_required"); }
            } ],
            lastname: [ {
                required: true,
                msg: function() { return i18n.t("user.lastname_required"); }
            } ],
            password: [ {
                required: true,
                msg: function() { return i18n.t("user.password_required"); }
            }, {
                passwordStrict: true,
            } ],
            password_repeat: [ {
                equalTo: "password",
                msg: function() { return i18n.t("user.confirm_password_invalid"); }
            }, {
                passwordStrict: true,
            } ],
            email: [ {
                required: true,
                msg: function() { return i18n.t("user.email_required"); }
            }, {
                pattern: "mail",
                msg: function() { return i18n.t("validation.invalid_email"); }
            } ],
            dni: [ {
                required: true
            } ],
            phone: [{
                required: true,
                msg: function() {
                    return i18n.t('validation.phone_required')
                },
            }, {
                rangeLength: [9, 20],
                msg: function() {
                    return i18n.t('validation.phone_length')
                },
            }, {
                pattern: 'phone',
                msg: function() {
                    return i18n.t('validation.phone_invalid')
                },
            }],
        },

        initialize: function() {},

        changeEmail: function() {
            this.save({}, {
                url: App.urlTo("user/account/email-update-request"),
                success: function() {
                    this.trigger("user:email-update-request-success");
                }.bind(this),
                error: function(model, response) {
                    this.trigger("user:email-update-request-error", response.responseJSON.errors);
                }.bind(this)
            });
        },

        requestChangePassword: function() {
            this.save({}, {
                type: "post",
                url: App.urlTo("user/account/password-reset"),
                success: function() {
                    this.trigger("user:request-change-password", "success");
                }.bind(this),
                error: function(model, response) {
                    this.trigger("user:request-change-password-error", response.responseJSON.errors);
                }.bind(this)
            });
        },

        resetPasswordUpdate: function() {
            this.save({}, {
                type: "post",
                url: App.urlTo("user/account/reset-password-update", {
                    token: this.get("reset_password_token")
                }),
                success: function() {
                    this.trigger("user:reset-password-success");
                }.bind(this),
                error: function(model, response) {
                    this.trigger("user:reset-password-error", response.responseJSON.errors);
                }.bind(this)
            });
        }

    });

    return UserModel;

});
