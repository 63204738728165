define([
    "jquery",
    "underscore",
    "backbone",
    "components/field",
    "components/i-field",
    "components/button",
    "components/selectize-dropdown",
    "components/checkbox",
    "utils/mobile-checker",
    "utils/number-formatter",
    "moment",
    "moment/es",
    "backbone-validation"
], function($, _, Backbone, Field, IField, Button, SelectizeDropdown, Checkbox, MobileChecker, NumberFormatter, moment) {
    "use strict";

    function _super(instance, method, args) {
        return instance._super.prototype[method].apply(instance, args);
    }

    var BaseView = Backbone.View.extend({
        modelChange: false,
        elementChange: false,

        _super: Backbone.View,

        // Backbone.View constructor override:
        // sets up binding controls around call to super.
        constructor: function(options) {
            _.extend(this, _.pick(options || {}, [ "bindings", "bindingHandlers", "shortcuts" ]));
            _.extend(this.bindingHandlers, this.defaultBindingHandlers, this.bindingHandlers);
            _super(this, "constructor", arguments);
            this.applyBindings();
            this.bindShortcuts();
            this.createButtons();
            this.createDropdowns();
            this.createCheckboxes();
            this.createFields();
            this.createIFields();
        },

        bindingHandlers: {},
        defaultBindingHandlers: {
            text: {
                set: function($el, value) {
                    $el.text(value);
                },
                get: function($el) {
                    return $el.text();
                }
            },
            html: {
                set: function($el, value) {
                    $el.html(value);
                },
                get: function($el) {
                    return $el.html();
                }
            },
            htmlNumber: {
                set: function($el, value) {
                    if (value || value == 0) {
                        $el.html(NumberFormatter.toDecimal(value, { locale: App.LANGUAGE }));
                    } else {
                        $el.html("");
                    }
                },
                get: function($el) {
                    var value = $el.html()

                    if (value) {
                        return NumberFormatter.parseFloatString(value)
                    } else {
                        return null
                    }
                }
            },
            value: {
                set: function($el, value) {
                    $el.val(value);
                },
                get: function($el) {
                    return $el.val();
                }
            },
            iValue: {
                set: function($el, value) {
                    var name = $el.attr('data-name')
                    this.fields[name].val(value);
                },
                get: function($el) {
                    var name = $el.attr('data-name')
                    return this.fields[name].val();
                }
            },
            number: {
                set: function($el, value) {
                    if (_.isNumber(value)) {
                        $el.val(NumberFormatter.toDecimal(value, { locale: App.LANGUAGE }));
                    } else {
                        $el.val("");
                    }
                },
                get: function($el) {
                    var value = $el.val()

                    if (value !== null && value !== undefined) {
                        return NumberFormatter.parseFloatString(value)
                    } else {
                        return null
                    }
                }
            },
            // @deprecated
            decimal: {
                set: function($el, value) {
                    if (value) {
                        $el.val(NumberFormatter.asDecimal(value));
                    } else {
                        $el.val("");
                    }
                },
                get: function($el) {
                    return $el.val();
                }
            },
            // @deprecated
            decimalHTML: {
                set: function($el, value) {
                    if (value) {
                        $el.html(NumberFormatter.asDecimal(value));
                    } else {
                        $el.html("");
                    }
                },
                get: function($el) {
                    return $el.html();
                }
            },
            dropdown: {
                set: function($el, value) {
                    $el.data("dropdown").val(value || "");
                },
                get: function($el) {
                    return $el.data("dropdown").val();
                }
            },
            checkbox: { //For the checkbox/toggle component
                set: function($el, value) {
                    $el.data("checkbox").checked(Boolean(value));
                },
                get: function($el) {
                    return Boolean($el.data("checkbox").checked());
                }
            },
            checked: {
                set: function($el, value) {
                    $el.prop("checked", value);
                },
                get: function($el) {
                    return $el.prop("checked");
                }
            },
            radio: {
                set: function($els, value) {
                    _.each($els, function(el) {
                        var $el = $(el);
                        if ($el.val() === value) {
                            $el.prop("checked", true);
                        }
                    });
                },
                get: function($el) {
                    return $("<div>").append($el.clone()).find(":checked").val();
                }
            },
            dates: {
                set: function($el, value) {
                    if (value){
                        $el.val(moment(value).format("DD/MM/YYYY HH:mm"));
                    }
                },
                get: function($el) {
                    return moment($el.val(), "DD/MM/YYYY HH:mm").utc().format();
                }
            },
            datepicker: {
                set: function($el, value) {
                    if (value) {
                        if (MobileChecker.isMobile()) {
                            $el.val(moment(value).format("YYYY-MM-DD"));
                        } else {
                            $el.val(moment(value).format("L"));
                        }
                    } else {
                        $el.val("");
                    }
                },
                get: function($el) {
                    var val = "";

                    if ($el.val()) {
                        if (MobileChecker.isMobile()) {
                            val = moment($el.val(), "YYYY-MM-DD").format("YYYY-MM-DD");
                        } else {
                            val = moment($el.val(), "L").format("YYYY-MM-DD");
                        }
                    }
                    return val;
                }
            },
            dateTimePicker: {
                set: function($el, value) {
                    if (value) {
                        if (MobileChecker.isMobile()) {
                            $el.val(moment(value).format("YYYY-MM-DD\THH:mm"));
                        } else {
                            $el.val(moment(value).format("DD/MM/YYYY      HH:mm"));
                        }
                    }
                },

                get: function($el) {
                    var val = "";
                    if ($el.val()) {
                        if (MobileChecker.isMobile()) {
                            val = moment($el.val(), "YYYY-MM-DD\THH:mm").format("YYYY-MM-DD\THH:mm");
                        } else {
                            val = moment($el.val(), "DD/MM/YYYY HH:mm").format("YYYY-MM-DD\THH:mm");
                        }
                    }
                    return val;
                }
            },

            timestamp: {
                set: function($el, value) {
                    if (value) {
                        if (MobileChecker.isMobile()) {
                            $el.val(moment(value).format("YYYY-MM-DD\THH:mm"));
                        } else {
                            $el.val(moment(value).format("L LT"));
                        }
                    }
                },

                get: function($el) {
                    var val = "";
                    if ($el.val()) {
                        if (MobileChecker.isMobile()) {
                            val = moment($el.val(), "YYYY-MM-DD\THH:mm").format("X");
                        } else {
                            val = moment($el.val(), "L LT").format("X");
                        }
                    }
                    return val;
                }
            },

            dateTimeHtml: {
                set: function($el, value) {

                    if (value) {
                        $el.html(moment(value).format("DD/MM/YYYY HH:mm"));
                    } else{
                        $el.html();
                    }
                }
            },

            selectize: {
                set: function($element, value) {
                    if ($element.length) {
                        if (MobileChecker.isMobile()) {
                            $element.val(value);
                        } else {
                            $element.data().dropdown.setValue(value);
                        }
                    }
                },
                get: function($element) {
                    return MobileChecker.isMobile() ? $element.find("select").val() : $element.data().dropdown.getValue();
                }
            },

            qtyAmount: {
                set: function($el, value) {
                    if (value && _.isNumber(value.amount)) {
                        $el.val(NumberFormatter.toCurrency(value.amount, value.currency));
                    } else {
                        $el.val("");
                    }
                },

                get: function($el, value) {
                    var amount = $el.val()
                    if (value && amount) {
                        return {
                            amount: NumberFormatter.parseFloatString(amount),
                            currency: value.currency,
                        };
                    }

                    return {
                        amount: null,
                        currency: value.currency,
                    };
                }
            },

            qtyAmountText: {
                set: function($el, value) {
                    if (value && _.isNumber(value.amount)) {
                        $el.text(NumberFormatter.toCurrency(value.amount, value.currency));
                    } else {
                        // QUESTION: Is this necessary?
                        $el.text("");
                    }
                },

                get: function() {}
            }
        },

        bindShortcuts: function() {
            if (this.shortcuts) {
                $("body").on("keyup", function(e) {
                    var unicode = e.keyCode ? e.keyCode : e.charCode;
                    _.each(this.shortcuts, function(handler, shortcut) {
                        if (keyCodes[shortcut] == unicode && e.altKey) {
                            handler = handler.split(" ");
                            //Manage "click [selector-of-element]" handler
                            if (handler.length == 2) {
                                this.$el.find(handler[1]).trigger(handler[0]);
                            } else {
                                //Manage "[function]" handler
                                this[handler].call(this);
                            }
                            return;
                        }
                    }.bind(this));
                }.bind(this));
            }
        },

        /**
         * Apply all bindings
         */
        applyBindings: function() {
            _.each(this.bindings, function(binding, key) {
                var $el,
                    model,
                    selector, //key[0]
                    tagName, //key[1]
                    property;

                key = key.split(":");
                selector = key[0];
                tagName = (key.length > 1 ? key[1] : "");
                $el = this.$el.find(tagName + selector);

                binding = binding.split(":");
                //extract model and property
                property = binding[1].split("@");
                if (property.length > 1) {
                    model = property[0];
                    property = property[1];
                } else {
                    model = "model";
                    property = property[0];
                }

                //Get
                if (this.bindingHandlers[binding[0]].get) {
                    $el.on("change", function(e) {

                        if (this.modelChange !== key[0] + "-" + binding[0] + "-" + property) {
                            this.elementChange = key[0] + "-" + binding[0] + "-" + property;
                            this[model].set(
                                property,
                                this.bindingHandlers[binding[0]].get.call(this, $(e.currentTarget), this[model].get(property), this),
                                {
                                    validate: true,
                                    validateAll: false
                                });
                        }

                        this.elementChange = false;
                    }.bind(this));

                }
                //Set
                if (this.bindingHandlers[binding[0]].set) {
                    this.listenTo(this[model], "change:" + property, function(model, value) {
                        if (this.elementChange !== key[0] + "-" + binding[0] + "-" + property) {
                            this.modelChange = key[0] + "-" + binding[0] + "-" + property;
                            this.bindingHandlers[binding[0]].set.call(this, $el, value, this);
                        }

                        this.modelChange = false;
                    }.bind(this));
                }

            }.bind(this));
        },

        /**
         * Remove all bindings
         */
        removeBindings: function() {
            _.each(this.bindings, function(binding, key) {
                var $el = this.$el.find(key),
                    property,
                    model;

                $el.off("change");

                binding = binding.split(":");
                //extract model and property
                property = binding[1].split("@");
                if (property.length > 1) {
                    model = property[0];
                    property = property[1];
                } else {
                    model = "model";
                    property = property[0];
                }
                this.stopListening(this[model], "change:" + property);
            }.bind(this));
        },

        /**
         * Set data of all bindings at once
         */
        setData: function() {
            _.each(this.bindings, function(binding, key) {
                var selector,
                    tagName,
                    $el,
                    property,
                    model;

                binding = binding.split(":");
                //extract model and property
                property = binding[1].split("@");
                if (property.length > 1) {
                    model = property[0];
                    property = property[1];
                } else {
                    model = "model";
                    property = property[0];
                }

                key = key.split(":");
                selector = key[0];
                tagName = (key.length > 1 ? key[1] : "");

                $el = this.$el.find(tagName + selector);

                this.bindingHandlers[binding[0]].set.call(this, $el, this[model].get(property), this);

            }.bind(this));
        },

        /**
         * Creation of componentes
         */

        createButtons: function() {
            var $buttons = this.$el.find(".js-loading-button");
            if ($buttons.length) {
                _.each($buttons, function(el) {
                    this.buttons = this.buttons || {};
                    this.buttons[el.id] = new Button({
                        el: el
                    });
                }.bind(this));
            }
        },

        createCheckboxes: function() {
            var $checkboxes = this.$el.find(".js-checkbox");
            if ($checkboxes.length) {
                _.each($checkboxes, function(el) {
                    var $checkbox = $(el),
                        checkboxComponent = $checkbox.data("checkbox"),
                        checkboxName = $checkbox.attr("data-name");

                    this.checkboxes = this.checkboxes || {};
                    if (checkboxComponent) {
                        this.checkboxes[checkboxName] = checkboxComponent;
                    } else {
                        this.checkboxes[checkboxName] = new Checkbox({
                            el: el
                        });
                    }
                }.bind(this));
            }
        },

        createDropdowns: function() {
            var $dropdowns = this.$el.find(".js-dropdown");
            if ($dropdowns.length) {

                _.each($dropdowns, function(el) {
                    var $dropdown = $(el),
                        dropdownComponent = $dropdown.data("dropdown"),
                        dropdownName = $dropdown.attr("data-name");

                    this.dropdowns = this.dropdowns || {};
                    if (dropdownComponent) {
                        this.dropdowns[dropdownName] = dropdownComponent;
                    } else {
                        this.dropdowns[dropdownName] = new SelectizeDropdown({
                            $el: $(el)
                        });
                    }

                }.bind(this));
            }
        },

        createFields: function() {
            _.each(this.$el.find(".js-field"), function(el) {
                var fieldName = $(el).attr("data-name");

                this.fields = this.fields || {};
                this.fields[fieldName] = new Field({
                    el: this.$el.find(".js-field-" + fieldName),
                    name: fieldName
                });
            }.bind(this));
        },

        createIFields: function() {
            _.each(this.$el.find(".js-i-field"), function(el) {
                var fieldName = $(el).attr("data-name");

                this.fields = this.fields || {};
                this.fields[fieldName] = new IField({
                    el: this.$el.find(".js-i-field-" + fieldName),
                    name: fieldName
                });
            }.bind(this));
        },

        /**
         * Error management in the view
         */

        setErrors: function(errors) {
            if (!_.isEmpty(errors)) {
                var notiErrors = "";
                _.mapObject(errors, function(value, key) {

                    if (key === "noti") {
                        notiErrors += value.join(",");
                    } else if (key === "noti-redirect") {
                        window.setTimeout(function() {
                            window.location.href = value;
                        }, 3000);
                    } else {
                        if (!this.fields || (this.fields && !this.fields[key])) {
                            if (this.$el.find(".js-error-" + key).length) {
                                this.$el.find(".js-error-" + key).html(value.join(",")).show();
                            } else {
                                notiErrors += value.join(",");
                            }

                        } else {
                            this.fields[key].showError(value.join("<br />"));
                        }
                    }

                }.bind(this));

                if (notiErrors) {
                    App.Noti.show(notiErrors, "error");
                }
            }
        },

        clearErrors: function() {
            _.each(this.fields, function(field) {
                field.hideError();
            });
        }
    });

    return BaseView;
});
