define([
    "jquery",
    "underscore",
    "backbone"
    ], function($, _, Backbone) {

        "use strict";

        var iFieldComponent = Backbone.View.extend({

            events: {
                "focus js-field-i-field": "onFocus",
                "blur .js-field-i-field": "onBlur",
                "keydown .js-field-i-field": "hideError",
                "change .js-field-i-field": "onChangeInput",
            },

            initialize: function(options) {
                this.value = {};
                this.options = options || {};
                this.render();

                this.$el.ival = this.ival
            },

            onFocus: function() {
                this.$el.addClass("is-focus");
            },

            onChangeInput: function(event) {
                var item = $(event.target);
                this.value[item.attr('data-lang')] = item.val()
            },

            onBlur: function() {
                this.$el.removeClass("is-focus");
            },

            disable: function() {
                this.$el.addClass("is-disabled");
                this.$inputs.each(function() {
                    $(this).attr("disabled", "disabled");
                })
            },

            enable: function() {
                this.$el.removeClass("is-disabled");
                this.$inputs.each(function() {
                    $(this).attr("disabled", null);
                })
            },

            focus: function() {
                setTimeout(function() {
                    this.$inputs.each(function() {
                        $(this).is(":visible") && $(this).focus();
                    })
                }.bind(this), 100);
            },

            showError: function(message) {
                this.$el.addClass("field--error")
                    .find(".js-field-" + this.options.name + "-error")
                    .html(message);
            },

            hideError: function() {
                this.$el.removeClass("field--error");
            },

            clear: function() {
                this.$inputs.each(function() { $(this).val('') })
                this.hideError();
            },

            val: function(value) {
                var values = {};
                
                this.$inputs.each(function() {
                    var lang = $(this).attr('data-lang')
                    values[lang] = $(this).val()
                })
                
                if (value) {
                    this.$inputs.each(function() {
                        var language = $(this).attr('data-lang');
                        $(this).val(value[language])
                    })
                }
                
                if (value === null) {
                    this.clear();
                }

                return values
            },

            render: function() {
                this.$inputs = this.$el.find(".js-field-i-field");
            }

        });

        return iFieldComponent;

    });
