define([
    "jquery",
    "underscore",
    "backbone",
    ], function($, _, Backbone) {

        "use strict";

        var Tooltip = Backbone.View.extend({
            el: ".js-tooltip",
            events: {
                "click .js-tooltip-label": "onClick",
                "click .js-tooltip-close": "onClose",
                mouseover: "onShow",
            },

            initialize: function() {
                this.zTopIndex = 0;
            },

            onClick: function(event) {
                var $parent    = $(event.currentTarget).parent(),
                    $textBlock = $(".js-tooltip-texts", $parent);

                $textBlock.toggleClass("is-visible");
                if ($textBlock.hasClass("is-visible")) {
                    $(".js-tooltip-close", $parent).show();
                    this.rePositioned($textBlock);
                } else {
                    $(".js-tooltip-close", $parent).hide();
                }
            },

            onClose: function(event) {
                event.stopPropagation();

                var $myparent  = ($(event.currentTarget)).parents(".js-tooltip"),
                    $textBlock = $(".js-tooltip-texts", $myparent);

                $(event.currentTarget).hide();
                $textBlock.removeClass("is-visible");
            },

            onShow: function(event) {
                var $parent = $(event.currentTarget),
                    $textBlock = $(".js-tooltip-texts", $parent);
                this.rePositioned($textBlock);
            },

            rePositioned: function($element) {
                var right  = $element.position().left + $element.outerWidth(),
                    offset = $(document).outerWidth() - right;

                if (offset < 10) {
                    offset = offset - 30;
                } else {
                    offset = 0;
                }

                $element.css("margin-left", offset);

                this.reZIndexed($element);
            },

            reZIndexed: function($element) {
                var zIndex = $element.css("z-index");

                if ((zIndex != undefined) && (this.zTopIndex < zIndex)) {
                    this.zTopIndex = zIndex;
                }

                this.zTopIndex += 1;
                $element.css("z-index", this.zTopIndex);
            },

        });

        return Tooltip;

    });
