/* globals dataLayer */

define([], function() {
        "use strict";

        var Analytics = {
            trackEvent: function(category, action, label) {
                if (typeof ga != "undefined"){
                    ga("send", "event", category, action, label);
                }
            },

            trackSendContactForm: function(url) {
                if (typeof dataLayer == "undefined") return;
                dataLayer.push({
                    event: "sendContactForm",
                    payload: url
                });
            },

            trackAddToCart: function(productData) {
                if (typeof dataLayer == "undefined") return;
                dataLayer.push({
                    event: "addToCart",
                    ecommerce: {
                        add: {
                            products: this.getProductsArray(productData)
                        }
                    }
                });
            },

            trackCheckoutStep: function(productData, stepNumber) {
                if (typeof dataLayer == "undefined") return;

                dataLayer.push({
                    event: "checkout",
                    ecommerce: {
                        checkout: {
                            actionField: {
                                step: stepNumber,
                            }
                        },
                        products: this.getProductsArray(productData)
                    }
                });
            },

            trackPurchase: function(order, productData, id) {
                if (typeof dataLayer == "undefined") return;

                dataLayer.push({
                    event: "checkout",
                    ecommerce: {
                        purchase: {
                            actionField: {
                                id          : id,
                                affiliation : "Parkingdoor Tienda Online",
                                revenue     : order.get("subtotal"),
                                tax         : (order.get("tax")).toFixed(2),
                                shipping    : 0
                            }
                        },
                        products: this.getProductsArray(productData)
                    }
                });
            },

            getProductsArray: function(productData) {
                var product,
                    products = [];

                for (var i = 0; i < productData.length; i++) {
                    product = productData[i];
                    products.push({
                        name     : product.product_name,
                        id       : product.id,
                        price    : product.priceWithoutFormat,
                        brand    : "Parkingdoor",
                        variant  : product.type,
                        quantity : product.units
                    });
                }

                return products;
            }
        };

        return Analytics;
    });
