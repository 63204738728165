define([
    "jquery",
    "underscore",
    "backbone"
    ], function($, _, Backbone) {
        "use strict";

        var Modal = Backbone.View.extend({

            closedOptionsEnabled: true,

            initialize: function() {
                this.render();
            },

            onKeyUp: function(event) {
                if ($(".js-modal-close", this.$currentModal).length > 0) {
                    if (event.keyCode === 27) {
                        this.closeModalAction();
                    }
                }
            },

            setTitle: function(title) {
                this.$currentModal.find(".js-modal-title").html(title);
            },

            showModal: function(e, options) {

                var $body = $("body");

                if (this.$currentModal) {
                    this.$currentModal.removeClass("is-visible");
                    this.previousOptions = this.options;
                    this.$previousModal = this.$currentModal;
                }

                App.Modal.closedOptions(true);

                this.options = options || {};

                if (e){
                    e.preventDefault();
                    e.stopPropagation();
                }

                if (this.options.modalElement) {
                    this.$currentModal = $(this.options.modalElement).addClass("is-visible");
                } else {
                    this.$currentModal = $($(e.currentTarget).attr("data-modal")).addClass("is-visible");
                }

                if (this.options.isFixed){
                    $body.addClass("modal-is-visible");
                } else {
                    this.$currentModal.find(".js-modal-close").on("click", this.closeModalAction.bind(this));
                    $body.addClass("modal-is-visible");
                }

                this.activateKeyUpAction();

                return this.$currentModal;
            },

            activateKeyUpAction: function() {
                $("body").off("keyup").on("keyup", this.onKeyUp.bind(this))
                // Hide responsive menu if it's visible
                    .removeClass("menu-is-visible");
            },

            closeModalAction: function(e) {
                if (this.closedOptionsEnabled) {
                    this.closeModal(e);
                }
            },

            closedOptions: function(value) {
                var state = this.closedOptionsEnabled;
                if (value !== undefined) {
                    this.closedOptionsEnabled = value;
                    var $btn = $(".js-modal-close", this.$currentModal);
                    if (!this.closedOptionsEnabled) {
                        $btn.hide();
                    } else {
                        $btn.show();
                    }
                }
                return state;
            },

            closeModal: function(e) {
                $("body").removeClass('modal-fixed');
                if (e) {
                    if ($(e.currentTarget).hasClass("js-modal-close") || ($(e.target).hasClass("modal__wrapper"))) {
                        if (this.$currentModal) {
                            this.$currentModal.removeClass("is-visible");
                            this.$currentModal.find(".js-modal-close").off("click");
                        }
                        this.destroyModalView();

                        e.preventDefault();
                        e.stopPropagation();
                    }
                } else {
                    if (this.$currentModal) {
                        this.$currentModal.removeClass("is-visible");
                        this.$currentModal.find(".js-modal-close").off("click");
                    }
                    this.destroyModalView();
                }
            },

            hideModalCloseButton: function() {
                this.$currentModal.find(".js-modal-close").hide();
            },

            destroyModalView: function() {
                if (this.options.modalView && this.options.modalView.destroyView){
                    this.options.modalView.destroyView();
                }

                if (this.$previousModal) {
                    this.options = this.previousOptions;
                    this.$currentModal = this.$previousModal;
                    this.$currentModal.addClass("is-visible");
                    this.$previousModal = null;
                    this.activateKeyUpAction();
                } else {
                    this.$currentModal = null;
                    $("body").removeClass("modal-is-visible");
                    $("body").off("keyup");
                }

                this.trigger("modal:close");

            },

            render: function() {
                $(".js-modal-trigger").click(this.showModal.bind(this));

            },

            hideNotice: function() {
                this.$currentModal.find(".js-modal-notice").hide();
            },

            showNotice: function() {
                this.$currentModal.find(".js-modal-notice").show();
            }

        });

        return Modal;

    });
