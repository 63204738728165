define([
    "jquery",
    "underscore",
    "backbone",
    "i18next/i18next",
    "components/button"

], function($, _, Backbone, i18n) {
        "use strict";

        var Noti = Backbone.View.extend({
            el: ".js-noti",

            offsetTop: null,
            initialPosition: null,

            options: {
                animationOpen: "bounceInUp",
                animationClose: "bounceOutDown"
            },

            events: {
                click : "hide"
            },

            classes: {
                open: "is-open animated ",
                close: "animated "
            },

            initialize: function(options) {
                this.options = _.extend(this.options, options);
                this.setAnimationClasses();
                this.offsetTop = this.$el.offset().top;
                this.initialPosition = this.$el.css("position");
            },

            show: function(message, type, header) {
                this.$el.show();
                this.reallocateOnScroll();
                if (this.is_open){
                    this.hide();
                    window.setTimeout(function() {
                        this.showHelper(message, type, header);
                    }.bind(this),100);
                } else {
                    this.showHelper(message, type, header);
                }
            },

            reallocateOnScroll: function() {
                if (this.options.reallocateOnScroll === true) {
                    var scroll = $(window).scrollTop();
                    this.$el.css("position", this.initialPosition);

                    if ($("header").length > 0 ) {
                        this.$el.css({
                            position : this.initialPosition,
                            top      : 90
                        });
                    } else {
                        this.$el.css({
                            position : this.initialPosition,
                            top      : 0
                        });
                    }

                    if (scroll > this.offsetTop + 10) {
                        this.$el.css({
                            position : "fixed",
                            top      : 0
                        });
                    }
                }
            },

            showHelper: function(message, type, header) {

                var defaultHeader  = ((type === "error") ? "noti.fail" : "noti.success");

                this.$el.find(".js-noti-text").html(message).show();

                if (header === undefined) {
                    this.$el.find(".js-noti-header").html(i18n.t(defaultHeader));
                } else {
                    this.$el.find(".js-noti-header").html(header);
                }

                this.$el.removeClass("noti--success noti--error").addClass("noti--" + (type || "success"));

                this.$el.removeClass(this.classes.close).addClass(this.classes.open);

                if (this.hideTimeout){
                    clearTimeout(this.hideTimeout);
                }
                this.hideTimeout = setTimeout(this.hide.bind(this), 10000);

                this.is_open = true;
            },

            hide: function() {
                this.$el.removeClass(this.classes.open).addClass(this.classes.close);
                this.is_open = false;
                setTimeout(function() {if (!this.is_open) {this.$el.hide()}}.bind(this), 1000);
            },

            setAnimationClasses: function() {
                this.classes =  {
                    open: this.classes.open + this.options.animationOpen,
                    close: this.classes.close + this.options.animationClose
                };
            }
        });

        return Noti;

    });
