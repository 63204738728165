define([
    "jquery",
    "underscore",
    "backbone",
    ], function($, _, Backbone) {
        "use strict";

        var Button = Backbone.View.extend({
            el: ".js-loading-button",
            previousIcon: "",
            autoLoading: true,

            initialize: function() {

                this.listenTo(App.Events, "button:" + this.$el.attr("id") + ":hide-loading", this.hideLoading.bind(this));
                this.listenTo(App.Events, "button:" + this.$el.attr("id") + ":show-loading", this.showLoading.bind(this));
                this.listenTo(App.Events, "buttons:hide-loading", this.hideLoading.bind(this));

                this.$icon = this.$el.find("i");
                if (this.$icon.length){
                    this.previousIcon = this.$icon[0].className;
                }

                if (this.$el.attr("data-auto-loading") && this.$el.attr("data-auto-loading") === "false") {
                    this.autoLoading = false;
                }
            },

            events: {
                click: "startLoading"
            },

            startLoading: function(e, force) {
                if (this.autoLoading || force) {
                    this.showLoading();
                }
                return this;
            },

            showLoading: function() {
                if (!this.previousIcon){
                    this.$el.addClass("btn--icon");
                }
                if (this.$icon.length){
                    this.$icon.removeClass(this.previousIcon).addClass("icon-spin animate-spin");
                }

                this.disableTimeout = setTimeout(function() {
                    this.$el.attr("disabled", "disabled");
                }.bind(this), 1);
                return this;
            },

            hideLoading: function() {
                if (this.disableTimeout){
                    clearTimeout(this.disableTimeout);
                }
                this.$el.attr("disabled", null);
                if (!this.previousIcon){
                    this.$el.removeClass("btn--icon");
                }
                if (this.$icon.length){
                    this.$icon.removeClass("icon-spin animate-spin").addClass(this.previousIcon);
                }
                return this;
            },

            hideLoadingWithoutDisable: function() {
                if (this.disableTimeout){
                    clearTimeout(this.disableTimeout);
                }
                if (!this.previousIcon){
                    this.$el.removeClass("btn--icon");
                }
                if (this.$icon.length){
                    this.$icon.removeClass("icon-spin animate-spin").addClass(this.previousIcon);
                }
                return this;
            },

            disable: function() {
                this.$el.attr("disabled", "disabled");
                this.$el.on("click.linkdisabled", function() { return false; });
                return this;
            },

            hide: function() {
                this.$el.hide();
                return this;
            },

            show: function() {
                this.$el.show();
                return this;
            },

            enable: function() {
                this.$el.removeClass("is-disabled", null);
                this.$el.attr("disabled", null);
                this.$el.off("click.linkdisabled");
                return this;
            },

            attr: function(key, value) {
                return this.$el.attr(key, value);
            }
        });

        return Button;

    });
