define([
    "jquery",
    "moment",
    "lib/jQuery-Storage-API/jquery.storageapi"
    ], function($, moment) {
        "use strict";

        var LocalStorage = {
            isEnabled: function() {
                try {
                    $.localStorage.set("elparking::test", "test");
                    $.localStorage.remove("elparking::test");
                    return true;
                } catch (e) {
                    return false;
                }
            },

            save: function(key, jsonData, expirationMin) {

                if (this.isEnabled()) {
                    var record = { value: jsonData, timestamp: (expirationMin ? expirationMin : "") };
                    $.localStorage.set(key, record);
                    return jsonData;
                } else {
                    return null;
                }

            },
            get: function(key) {
                if (this.isEnabled()) {
                    var record = $.localStorage.get(key), date;
                    if (!record){ return false; }

                    if (record.timestamp){
                        date = moment.utc(record.timestamp, "YYYY-MM-DD HH:mm:ss");
                        return (date.diff(moment()) > 0 && record.value);
                    } else {
                        return record.value;
                    }
                } else {
                    return null;
                }
            },
            remove: function(key) {
                if (this.isEnabled()) {
                    $.localStorage.remove(key);
                }
            }
        };

        return LocalStorage;
    });
