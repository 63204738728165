var Backbone = require('backbone')
var LocalStorage = require('utils/localstorage')

var ACCEPTED = '2'
var REJECTED = '3'

var CookieNoticer = Backbone.View.extend({
    el: '.js-cookie-noticer',

    events: {
        'click .js-btn-accept': 'onAccept',
        'click .js-btn-reject': 'onReject'
    },

    classes: {
        open: 'is-open animated slideInUp',
        close: 'is-close animated slideOutDown'
    },

    initialize: function () {
        this.render()
    },

    onAccept: function () {
        App.initAnalytics()
        LocalStorage.save('ParkingDoor:cookies', ACCEPTED)
        this.hide()
    },

    onReject: function () {
        LocalStorage.save('ParkingDoor:cookies', REJECTED)
        this.hide()
    },

    isAccepted: function () {
        return LocalStorage.get('ParkingDoor:cookies') === ACCEPTED
    },

    show: function () {
        this.$el.addClass(this.classes.open)
    },

    hide: function () {
        this.$el.removeClass(this.classes.open).addClass(this.classes.close)
    },

    render: function () {
        if (this.isAccepted()) {
            App.initAnalytics()
            this.$el.remove()
            return
        }

        this.show()
    }
})

module.exports = CookieNoticer
