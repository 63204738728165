define([
    "underscore",
    "backbone",
    "../utils/logger"
    ], function(_, Backbone, logger) {

        "use strict";

        var YiiJsRouter = Backbone.View.extend({
            el: ".js-routes",

            routes: {},
            regexParams: /<([\w]+)([^\w|^>|^<][^>|^<]*)?>/gmi,
            regexMethods: /^(POST|PUT|GET|DELETE)\s*/g,

            initialize: function() {
                if (this.$el.length) {
                    this.rawRoutes = JSON.parse(this.$el.attr("data-json"));
                    this.$el.removeAttr("data-json");

                    this.parseRoutes();
                } else {
                    logger.warn("Error YiiJsRouter: No routes loaded");
                }
            },

            parseRoutes: function() {
                this.routes = _.mapObject(_.invert(this.rawRoutes), function(val) {

                    var params = this.getParams(val),
                        route = val.replace(this.regexMethods, "")
                                .replace(this.regexParams, ":$1");

                    return {
                        original_route: val,
                        route: route,
                        params: params
                    };
                }.bind(this));
            },

            getParams: function(path) {
                var re = this.regexParams,
                    params = [],
                    match;

                while ((match = re.exec(path)) !== null) {
                    if (match.index === re.lastIndex) {
                        re.lastIndex++;
                    }
                    params.push(match[1]);
                }

                return params;
            },

            generateUrl: function(path, params) {
                var routeObject = this.routes[ path ],
                    url;

                if (!routeObject || routeObject.route === undefined || routeObject.route == null) {
                    logger.warn("Error: missing route " + path);
                    return "";
                } else {
                    url = routeObject.route;
                }

                params = params || {};

                if (routeObject.params.length) {
                    if (!_.isEqual(routeObject.params, _.keys(params))) {
                        logger.warn("Error: missing params", routeObject.params);
                        return "";
                    } else {
                        _.each(_.keys(params), function(paramKey) {
                            url = url.replace(new RegExp(":" + paramKey, "g"), params[paramKey]);
                        });
                    }
                }

                return "/" + encodeURI(url);
            },

            generateRegexUrl: function(path) {
                var routeObject = this.routes[ path ],
                    url;

                if (!routeObject || routeObject.route == undefined || routeObject.route == null){
                    logger.warn("Error: missing route " + path);
                    return "";
                } else {
                    url = routeObject.route;
                }

                if (routeObject.params.length) {
                    _.each(routeObject.params, function(paramKey) {
                        url = url.replace(new RegExp(":" + paramKey, "g"), "([a-zA-Z0-9-_]+)");
                    });
                }

                url = url.replace(new RegExp("\/", "g"), "\\\/");

                return url;
            }
        });

        return YiiJsRouter;

    });
