define([
    "jquery",
    "underscore",
    "backbone",
    "backbone-nested",
    "i18next/i18next",
    "views/base",
    "utils/logger",
    "moment",
    "utils/number-formatter",
    "moment/es",
    "backbone-validation",
], function($, _, Backbone, BackboneNested, i18n, BaseView, Logger, moment, NumberFormatter) {
        "use strict";

        var HTMLEntities = '\\u00A0-\\u9999<>\'"\\&%'

        var keyCodes = {
            A: 65,
            B: 66,
            C: 67,
            D: 68,
            S: 83
        };

        /*******************
         * Views extension
         *********************/

        window.App.View = BaseView;

        /*********************
         * Models extension
         *********************/

        _.extend(Backbone.Model.prototype, {
            syncAttrs: {},
            get: function(attr) {
                var value = this.attributes[attr];
                return _.isFunction(value) ? value.call(this) : value;

            },

            toJSON: function() {
                var json = _.clone(this.attributes);
                _.each(json, function(value, key) {
                    if (typeof value == "function") {
                        delete json[key];
                    }
                    if ((value instanceof Backbone.Model) || (value instanceof Backbone.Collection)) {
                        json[key] = value.toJSON();
                    }
                });
                return json;
            },

            getSyncAttrs: function() {
                if (_.isEmpty(this.syncAttrs)) {
                    return this.toJSON();
                }
                var retVal = {};
                _.each(this.syncAttrs, function(attr) {
                    retVal[attr] = this.get(attr);
                }, this);
                return retVal;
            },

            bindWithHTML: function($el, options) {
                this.set(this.parseDataFromHTML($el),  options || { silent: true });
            },

            parseDataFromHTML: function(el) {
                var jsonData,
                    parsedData = {};
                if (typeof el === "string") {
                    parsedData.html = el;
                    el = $(el);
                }

                jsonData = el.attr("data-json");
                el.removeAttr("data-json");
                if (jsonData) {
                    try {
                        parsedData = _.extend(parsedData, JSON.parse(jsonData));
                    } catch (ex) {
                        Logger.log("Error binding: ", ex);
                    }
                }
                return parsedData;
            },

            unsetEmpty: function() {
                _.each(_.keys(this.attributes), function(attr) {
                    if (this.attributes[attr] !== 0 && !this.attributes[attr]) {
                        this.unset(attr, { silent: true });
                    }
                }.bind(this));
            },

            parse: function(response) {
                if (response) {
                    if (response.data) {
                        return response.data;
                    } else if (response.fields) {
                        return response.fields;
                    } else if (response.html) {
                        return this.parse({ data: this.parseDataFromHTML(response.html) });
                    } else {
                        return response;
                    }
                }
            },

            setDefaults: function(options) {
                options = options || { silent: true };

                this.clear();
                this.set(this.defaults, options);
            }

        });

        _.extend(Backbone.NestedModel.prototype, {
            get: function(attrStrOrPath) {
                var value = Backbone.NestedModel.walkThenGet(this.attributes, attrStrOrPath);
                return _.isFunction(value) ? value.call(this) : value;
            },
        });

        /**************************
        * Backbone Sync extension
        ***************************/

        var methodMap = {
            create: "POST",
            update: "PUT",
            delete: "DELETE",
            read:   "GET"
        };

        // Avoid cache in browser for ajax calls
        $.ajaxSetup({cache: false});

        Backbone.sync = function(method, model, options) {
            var type = methodMap[method];

            // Default options, unless specified.
            _.defaults(options || (options = {}), {
                emulateHTTP: Backbone.emulateHTTP,
                emulateJSON: Backbone.emulateJSON
            });

            // Default JSON-request options.
            var params = {type: type, dataType: "json"};

            // Ensure that we have a URL.
            if (!options.url) {
                params.url = _.result(model, "url") || urlError();
            }

            // Ensure that we have the appropriate request data.
            if (options.data == null && model && (method === "create" || method === "update" || method === "patch")) {
                params.contentType = "application/json";
                if (options.attrs) {
                    params.data = JSON.stringify(options.attrs);
                } else if (options.noEmpty) {
                    params.data = JSON.stringify(_.omit(model.getSyncAttrs(), function(value) {
                        return (value ? false : true);
                    }));
                } else {
                    params.data = JSON.stringify(model.getSyncAttrs());
                }
            }

            // For older servers, emulate JSON by encoding the request into an HTML-form.
            if (options.emulateJSON) {
                params.contentType = "application/x-www-form-urlencoded";
                params.data = params.data ? {model: params.data} : {};
            }

            // For older servers, emulate HTTP by mimicking the HTTP method with `_method`
            // And an `X-HTTP-Method-Override` header.
            if (options.emulateHTTP && (type === "PUT" || type === "DELETE" || type === "PATCH")) {
                params.type = "POST";
                if (options.emulateJSON) params.data._method = type;
                var beforeSend = options.beforeSend;
                options.beforeSend = function(xhr) {
                    xhr.setRequestHeader("X-CSRFToken", App.Constants.CSRF_TOKEN);
                    xhr.setRequestHeader("X-HTTP-Method-Override", type);
                    if (beforeSend) return beforeSend.apply(this, arguments);
                };
            };

            // Don"t process data on a non-GET request.
            if (params.type !== "GET" && !options.emulateJSON) {
                params.processData = false;
            }

            // If we"re sending a `PATCH` request, and we"re in an old Internet Explorer
            // that still has ActiveX enabled by default, override jQuery to use that
            // for XHR instead. Remove this line when jQuery supports `PATCH` on IE8.
            if (params.type === "PATCH" && noXhrPatch) {
                params.xhr = function() {
                    return new ActiveXObject("Microsoft.XMLHTTP");
                };
            }

            // Make the request, allowing the user to override any Ajax options.
            var xhr = options.xhr = Backbone.ajax(_.extend(params, options));
            model.trigger("request", model, xhr, options);
            return xhr;
        };

        /*********************************************************
         * Backbone.Validation extensions and configuration
         *********************************************************/

        _.extend(Backbone.Validation.callbacks, {
            valid: function(view, attr) {
                if (view.fields && view.fields[attr]) {
                    view.fields[attr].hideError();
                } else if (view.$el.find(".js-error-" + attr).length) {
                    view.$el.find(".js-error-" + attr).hide();
                }
            },
            invalid: function(view, attr, error) {
                if (view.fields && view.fields[attr]) {
                    view.fields[attr].showError(error);
                } else if (view.$el.find(".js-error-" + attr).length) {
                    view.$el.find(".js-error-" + attr).html(error).show();
                }
            }
        });

        Backbone.Validation.configure({
            forceUpdate: true,
            labelFormatter: "label"
        });

        // TODO: Translate this messages
        _.extend(Backbone.Validation.messages, {
            required       : "Debes indicar {0}",
            number         : "{0} debe ser un número",
            minLength      : "{0} debe tener al menos {1} caracteres",
            maxLength      : "{0} no puede tener más de {1} caracteres",
            acceptance     : "Debes aceptar {0}",
            range          : "{0} debe estar entre {1} y {2}",
            arrayMinLength : "{0} debe tener al menos {1} elementos",
        });

        _.extend(Backbone.Validation.validators, {
            number: function(value) {
                if (_.isNaN(value) ||_.isNull(value) || !_.isNumber(value)) {
                    return i18n.t("validator.number");
                }
            },
            i18nRequired: function(valueObject) {
                if (!valueObject) {
                    return i18n.t("validator.required");
                }
                var values = Object.values(valueObject)
                for (var value in values) {
                    if (!values[value]) {
                        return i18n.t("validator.required");
                    }
                }
            },
            i18nRequiredOnce: function(valueObject) {
                var filled = false;
                if (valueObject) {
                    var values = Object.values(valueObject)
                    for (var value in values) {
                        if (values[value]) {
                            filled = true;
                        }
                    }
                }
                if (!filled) {
                    return i18n.t("validator.required");
                }
            },
            passwordStrict: function(val) {
                var valid = !!/[A-Z]+/.test(val) && !!/[a-z]+/.test(val) && !!/[0-9]+/.test(val) && (val.length >= 8) && (val.length < 100)
                if (!valid) {
                    return i18n.t("validation.password_strict");
                }
            },
            date: function(value) {
                if (value && !moment(value, "YYYY-MM-DD HH:mm:ss").isValid()) {
                    return i18n.t("validator.date");
                }
            },
            validateProvince: function(value) {
                if (value === "Melilla" || value === "Ceuta" || value === "Palmas, Las" || value === "Balears, Illes") {
                    return "Lo sentimos, de momento solo permitimos envíos/recogidas en la península";
                }
            },
            arrayMinLength: function(value, attr, minLength, model) {
                if (value && value.length < minLength) {
                    return this.format(Backbone.Validation.messages.arrayMinLength, this.formatLabel(attr, model), minLength);
                }
            },
            arrayMaxLength: function(value, attr, maxLength, model) {
                if (value && value.length > maxLength) {
                    return this.format(Backbone.Validation.messages.arrayMaxLength, this.formatLabel(attr, model), maxLength);
                }
            },
            qtyAmountRequired: function(value) {
                if (!value || _.isNull(value.amount)) {
                    return i18n.t("validation.required_amount");
                }
            },
            qtyAmountNumber: function(value) {
                if (!value || _.isNull(value.amount)) {
                    return
                }

                if (!_.isNumber(value.amount) || _.isNaN(value.amount)) {
                    return i18n.t("validation.invalid_amount");
                }
            },
            qtyAmountMin: function(value, _attr, minValue) {
                if (!value || _.isNull(value.amount)) {
                    return
                }

                if (!_.isNumber(value.amount) || _.isNaN(value.amount) || value.amount < minValue) {
                    return i18n.t("validation.invalid_amount_minimum");
                }
            }
        });

        _.extend(Backbone.Validation.patterns, {
            time24: /([01]?[0-9]|2[0-3]):[0-5][0-9]/,
            iban: /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}/,
            dni: /(\d{8})([A-Za-z]{1})/,
            date: /^\d{4}-\d{2}-\d{2}$/,
            number: NumberFormatter.getNumberRegex(App.Constants.NUMBER_FORMAT),
            phone: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,7}$/,
            mail: new RegExp('^[^' + HTMLEntities + ']+@[^' + HTMLEntities + ']+\\.[^' + HTMLEntities + ']+$', 'i'),
        });


        // Run validation against the next complete set of model attributes,
        // returning `true` if all is well. If a specific `error` callback has
        // been passed, call that instead of firing the general `"error"` event.
        Backbone.Model.prototype._validate = function(attrs, options) {
            if (options.withoutValidation) {
                return true;
            } else {
                if (!options.validate || !this.validate) return true;
                if (options.validateAll !== false) {
                    attrs = _.extend({}, this.attributes, attrs);
                }
                var error = this.validationError = this.validate(attrs, options) || null;
                if (!error) return true;
                this.trigger("invalid", this, error, _.extend(options || {}, { validationError: error }));
                return false;
            }
        };

    });
